:root {
    --fnt-base: 'Jost', sans-serif;
    /*--clr-robin-blue: #141A46;*/
    --clr-robin-blue: #EC8B5E;
    --clr-crazy-blue: #55b3d5;
    --clr-black: #000;
    --clr-md-black: #111;
    --clr-dark: #191919;
    --clr-white: #fff;
    --clr-whitesmoke: #f5f5f5;
    /* grey shades */
    --clr-grey: #8e8e8e;
    --spacing: 0.1rem;
    --gradient: linear-gradient(90deg, rgba(16, 201, 195, 1) 0%, rgba(85, 179, 213, 1) 100%);
    --transition: all 300ms linear;
}
